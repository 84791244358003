import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogTags from "../components/blog-tags";
import BlogPostTags from "../components/blog-post-tags";

const BlogTile = ({
  date,
  title,
  subtitle,
  featured_image,
  post_body,
  uid,
  tags
}) => {
  const dateArr = date.split("-");
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 px-2 pb-4 mb-10">
      <Link
        to={`/blog/${dateArr[0]}/${dateArr[1]}/${dateArr[2]}/${uid}`}
        className="block text-center flex flex-col justify-between"
      >
        <div className="pb-3">
          <Moment format="MMM DD, YYYY" className="text-sm">
            {date}
          </Moment>

          <h3 className="text-3xl mb-2 font-serif leading-none">
            {title.text}
          </h3>
          <p className="text-sm">{subtitle.text}</p>
        </div>
        <Img
          fluid={{ ...featured_image.fluid, aspectRatio: 1 }}
          alt={title.text}
          className="w-full"
        />
      </Link>
      {tags && (
        <div className="text-left">
          <BlogPostTags tags={tags} />
        </div>
      )}
    </div>
  );
};

const BlogGrid = ({ posts }) => (
  <div className="max-w-6xl w-full mx-auto flex flex-wrap">
    {posts.map(({ data, uid }, index) => (
      <BlogTile key={index} uid={uid} {...data} />
    ))}
  </div>
);

export default BlogGrid;
