import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import classnames from "classnames";

const BlogTags = ({ currentTag }) => {
  const { allPrismicTag } = useStaticQuery(graphql`
    query BlogTagsQuery {
      allPrismicTag(sort: { fields: data___name }) {
        edges {
          node {
            data {
              name
            }
            uid
          }
        }
      }
    }
  `);

  return (
    <div className="px-4 mx-auto mb-6">
      {allPrismicTag.edges.map(({ node }) => (
        <Link
          to={currentTag === node.data.name ? `/blog` : `/blog/tag/${node.uid}`}
          className={classnames("text-xs uppercase px-1 hover:text-black", {
            "text-gray-600": currentTag !== node.data.name,
            "text-black font-bold": currentTag === node.data.name
          })}
          key={node.data.name}
        >
          {node.data.name}
        </Link>
      ))}
    </div>
  );
};

export default BlogTags;
