import React from "react";
import { Link } from "gatsby";

const BlogPostTags = ({ tags }) => (
  <>
    {tags.map(({ tag }) => (
      <Link
        to={`/blog/tag/${tag.document.uid}`}
        className="text-xs uppercase px-1 text-gray-600 hover:text-black"
        key={tag.document.uid}
      >
        {tag.document.data.name}
      </Link>
    ))}
  </>
);
export default BlogPostTags;
